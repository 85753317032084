import React, {useState} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/download.png';
import Maasai from '../images/Maasai.jpg';
import Energy from '../images/energy.jpg'
import Card1 from '../images/card1.jpg'
import Card3 from '../images/card3.jpg'
import calendar from '../images/calender-date-day-time-clock-schedule-event-comments-schedule-ico-115629448074tns0ghtw6-removebg-preview.png'
import mapper from '../images/535239.png'
import StudyImage from '../images/StudyImage.jpg'
import ResearchImage from '../images/ResearchImage.jpg'
import HappyStudents from '../images/Students Happy.jpg';
import Morio from '../images/Morio.jpg';
import Flying from '../images/flying.jpg';
import Graduations from '../images/graduations.jpg';
import Library from '../images/library.jpg';
import ManyStudents from '../images/Many Students.jpg';
import emailjs from 'emailjs-com'; // Import EmailJS


function Home() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [email, setEmail] = useState(''); // Add this line

const handleMouseEnter = () => {
  setShowDropdown(true);
};

const handleMouseLeave = () => {
  setShowDropdown(false);
};
const handleSubmit = (e) => {
  e.preventDefault();

  const templateParams = {
    to_email: 'subscriptions@beulah.co.ke', // Your email address
    from_email: email,
  };

  emailjs.send('service_1hxnt3b', 'template_umgbz1j',templateParams, 'PE7p7aFj5ZDrDMrwE')
    .then((response) => {
      console.log('Email sent successfully:', response);
      alert('Subscription successful!');
      setEmail('');
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      alert('Subscription failed. Please try again.');
    });
};
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4200,
  arrows: false
};



return (
  <div className="begin">
    <main className="main">
      
     
      
      <Slider {...settings}>
        <div className="hero1"  >
          <div className="hero-content">
            <div className="heros">
            <h1>Beulah Heights University Africa</h1>
            <p>World Class University of your choice</p>
            <a href="https://beulah.edu/" className="btn1">Read More</a>
            </div>
          </div>
        </div>
        <div className="hero2" >
          <div className="hero-content">
          <div className="heros">
            <h1>Welcome to Our Campus</h1>
            <p>Explore our diverse programs</p>
            <a href="https://beulah.edu/academics/" className="btn1">Learn More</a>
            </div>
          </div>
        </div>
        {/* Add more slides as needed */}
      </Slider>

      <div class="middle-section">
  <div class="study">
      <img class="image" src={StudyImage} alt="Study"/>
      <div class="content">
      <a href="https://beulah.edu/academics/" className="link-style">
          <h3>Study</h3>
          </a>
          <ul>
              
              <li><a href="https://beulah.edu/academics/undergraduate/"className="link-style">Undergraduate</a></li>
              <li><a href="https://beulah.edu/academics/master-programs/"className="link-style">Post Graduate </a></li>
              <li><a href="https://beulah.edu/academics/doctoral-programs/"className="link-style"> Diploma</a></li>
              <li><a href="https://beulah.edu/academics/adultlearning/"className="link-style"> Professional Courses</a></li>
          
          </ul>
      </div>
  </div>
  <div class="research">
      <img class="image" src={ResearchImage}alt="Research"/>
      <div class="content">
      <a href="https://beulah.edu/academics/library/" className="link-style">
          <h3>Research</h3>
          </a>
          <ul>
              <li><a href="https://beulah.edu/academics/library/"className="link-style">Explore Research
              </a></li>
              <li><a href="https://beulah.edu/academics/library/"className="link-style">Impact and Showcase </a></li>
              <li><a href="https://beulah.edu/academics/library/"className="link-style"> Institutes and Centers</a></li>
          </ul>
      </div>
  </div>
</div>

<div class="container mt-5">
  <div class="student-life-section">
    <div class="student-life-image">
      <img src={Maasai} class="img-fluid" alt="Student Life" style={{borderRadius: '10px'}}/>
    </div>
    <div class="student-life-content">
      
      <h3>STUDENT LIFE</h3>
      <div class="content-row">
        <div class="col-md-6">
          <h5>STUDENT LIFE</h5>
          <p>The student life at Beulah Heights University Africa is vibrant and diverse, providing students with opportunities for academic excellence, personal growth, and social engagement.</p>
        </div>
        <div class="col-md-6">
          <h5>MENTORING SERVICES</h5>
          <p>The mission of Beulah Heights University Africa is to provide an all-round quality education where students get professional training and acquire the personal qualities and necessary preparation to be responsible citizens, with values such as good conduct and hard work.</p>
        </div>
        <div class="col-md-12">
          <h5>HEALTH AND WELLNESS</h5>
          <p>The goal of Beulah Heights University Africa Medical Centre is to deliver comprehensive quality care that is accessible, affordable, available and patient-centered that meets internationally accepted standards.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<h1 className="text-center" style={{ color: 'white', fontWeight: 'bolder', backgroundColor:'#004080', maxWidth:'100%', margin: '0' }}>UNDERGRADUATE PROGRAMMES</h1>
    <br/>
  <div className="container">
      <div className="row justify-content-evenly">
        <div className="col-md-5">
          <a href="/inquire">
            <div className="image">
              <img src={HappyStudents} alt='maasai man'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire" className='homelink'><strong>Bachelor of Science in Organizational Leadership</strong></Link> 
            </h3>
            <p>
              Whether you want to become a business owner or manager within an organization, the
              principles of leadership and examples of historical leaders you study in the organizational
              leadership program will prepare you to rise to positions of influence in your career and
              effectively encourage others to accomplish their goals.
            </p>
        
            <div class="back-button-container">
            <a href="/inquire" class="back-button">
              <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
            </a>
          </div> 
        </div>

        <br/>

        <div className="col-md-5">
          <a href="/inquire">
            <div className="image">
              <img src={Graduations} alt='Graduation'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Bachelor of Science in Digital Media & Design</strong></Link>
            </h3>
            <p>
              Learn how to craft effective messaging for the in-demand fields of communication, marketing, graphics design, or web design. With 
              our online digital media degree, you'll become prepared to enter a dynamic, innovative, and growing field.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button" style={{marginTop:'46px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>
      </div>
  </div>  
  

  <br/>
  <br/>

  <h1 className="text-center" style={{color: 'white', fontWeight: 'bolder', backgroundColor:'#004080', maxWidth:'100%', margin: '0'}}>GRADUATE PROGRAMMES</h1>
    <br/>
  <div className="container">
      <div className="row justify-content-evenly">
        <div className="col-md-5">
          <a href="/inquire">
            <div className="image">
              <img src={Library} alt='library'/>
            </div>
          </a>
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Postgraduate Diploma in leadership: Entrepreneurship</strong></Link>
            </h3>
            <p>
              Be equipped as a leader to innovate new spaces and develop new solutions to the world's
              biggest problems. A three-course program, this Postgraduate Diploma is a good introduction to
              stewarding your gifts from the Lord to bring transformation recommended for those in 
              management, consulting, and entrepreneurs.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button">
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>

        <br/>

        <div className="col-md-5">
          <a href="/inquire">
            <div className="image">
              <img src={Flying} alt='flying'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Master of Arts in Theological Studies</strong></Link>
            </h3>
            <p>
              The Master of Arts in Theological Studies is designed to equip believers with an in-depth,
              comprehensive understanding of the theological foundations that guide the faith. This strong
              base will equip leaders to serve better in their various ministry contexts, whether from the pulpit
              or in the marketplace.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button"style={{marginTop:'46px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>
      </div>
  </div>
  
  <br/>
  <br/>

  <h1 className="text-center" style={{ color: 'white', fontWeight: 'bolder', backgroundColor:'#004080', maxWidth:'100%', margin: '0'}}>DOCTORAL PROGRAMMES</h1>
    <br/>

  <div className="container">
    <div className="row justify-content-evenly">
      <div className="col-md-5">
        <a href="/inquire">
          <div className="image">
            <img src={ManyStudents} alt='kenya'/>
          </div>
        </a>
           
          <br/>

        <h3>
          <Link to="/inquire"className='homelink'><strong>Doctor Of Ministry</strong></Link>
        </h3>
              <p>Beulah Heights University's DMin curriculum focuses on equipping leaders to meet the chalenges of leading in a Post-Christendom
                context. The curriculum design encourages ongoing spiritual formation, an ability to think critically about issues confronting our world,
                a devoted Christian lifestyle, and an active engagement of scholarship and ministry practice. Through a hybrid based and online
                instructional learning model, program competencies are taught through classroom instruction and synchronous and asynchronous
                learning using digital education platforms. Students complete a context and action research-based dissertation/ministry project,
                which will reflect the strategic work and learning the student encountered during the program. Persons who earn the BHU DMin
                degree (depending on the degree track) are credentialed and trained to serve in several ministry professional areas; Church Ministry,
                Chaplaincy, Para-church ministries, Higher-education/Seminary Faculty, Advocacy, Entrepreneurship, Conflict Resolution Specialist, etc.
              </p>
                
          <div class="back-button-container">
            <a href="/inquire" class="back-button">
              <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
            </a>
          </div>
      </div>
      
      <br/>

      <div className="col-md-5">
        <a href="/inquire">
          <div className="image">
            <img src={Morio} alt='African Students'/>
          </div>
        </a>
          
          <br/>

          <h3>
            <Link to="/inquire"className='homelink'><strong>Doctor of Philosophy</strong></Link>
          </h3>

          <p>The Doctor of Philosophy (Ph.D.) degree in Organizational Leadership is a 60-hour program that includes foundation, research, discipline
            and elective area coursework. While culminating with a comprehensive examination and dissertation. Coursework is focused on the
            blending of theory, real-world and practical applications that develop the knowldge, skills, and dispositions needed to strategically
            resolve conflict, manage high performing teams, lead multicultural and multigenerational workspaces; While intergrating the ideas
            and standard operations of globalization. Our curriculum is designed to further develop aspiring leaders who have the desire to further
            cultivate their knowledge base while enhancing the competencies needed to lead strategically and efficiently. In addition to leadership
            studies, discipline courses are tailored to reflect Business, Education, Ministry and the Social Science. All of which present principles for
            the growth and development for both a well rounded course of study and student.
          </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button"style={{marginTop:'20px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
          </div>

      </div>
        
    </div>

  </div>  
      
  <br/>
  <br/>



{/* courses section */}
<div className="App">
<header className="App-header">
  <h1 className="App-title">Short Courses / Events</h1>
  <section className='cards'>
    <div className="card">
      <div className="card-header">
        <div className="date">
          12
          <span className="month">APR</span>
        </div>
        <div className="card-title">
          Bussiness Intelligence
        </div>
      </div>
      <img src={Card1} alt="Bussiness Intelligence" className="card-image"/>
      <div className="card-content">
      <div className="top-content">
        <div className="learn-how-to">
        <div className="list">
        Learn How To:
        
          Create Data Base
        
          Analyze dashboard
        </div>
        </div>
        </div>
        <div className="card-footer2">
          <div className="date-time2">
          <img src={calendar} alt="Location Image" className="calendar-icon" />
             2024-04-08 @ 05:30 PM - 2024-06-07 @ 08:30 PM
          </div>
          <div className="location">
          <img src={mapper} alt="Time Image" className="time-image" />BHU Africa
          </div>   <button  className='coursebtn'>Class, Training, or Workshop</button>
       
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">
        <div className="date">
          08
          <span className="month">MAY</span>
        </div>
        <div className="card-title">
          Energy Management
        </div>
      </div>
      <img src={Energy} alt="Energy Management" className="card-image"/>
      <div className="card-content">
      <div className="top-content">
        <div className="location">
          Venue: BHU Africa
        
          Date: 08 may 2024
        
          Cost: Ksh. 20,000
        </div>
        </div>
        <div className="card-footer2">
          <div className="date-time2">
          <img src={calendar} alt="Location Image" className="calendar-icon" />
            2024-04-08 @ 05:30 PM - 2024-06-07 @ 08:30 PM
          </div>
          <div className="location">
          <img src={mapper} alt="Time Image" className="time-image" />BHU Africa
          </div>
          <button  className='coursebtn'>Class, Training, or Workshop</button>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">
        <div className="date">
          25
          <span className="month">MAY</span>
        </div>
        <div className="card-title">
          Online Writing
        </div>
      </div>
      <img src={Card3} alt="Online Writing" className="card-image"/>
      <div className="card-content">
      <div className="top-content">
        <div className="location">
          Venue: BHU Africa
        
          Date: 08 may 2024
        
          Cost: Ksh. 20,000
        </div>
        </div>
        <div className="card-footer2">
          <div className="date-time2">
          <img src={calendar} alt="Location Image" className="calendar-icon" />
            2024-04-08 @ 05:30 PM - 2024-06-07 @ 08:30 PM
          </div>
          <div className="location">
          
         <img src={mapper} alt="Time Image" className="time-image" /> BHU Africa</div>
          
          <button  className='coursebtn'>Class, Training, or Workshop</button>
        </div>
      </div>
    </div>
    
  </section>
</header>
</div>

    </main>

      <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(254) 743 777 277</a></li>
                <li><a href="mailto:info@beulahheights.edu">info@beulah.ac.ke</a></li>
                <li><a href="www.beulahheights.edu">www.beulahheights.edu</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights University Africa</strong></h4>
              <ul>
                <li>4240 North 15th Avenue</li>
                <li>Phoenix, AZ 85015</li>
                <li>Tel (254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="/Academics">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="/">Graduate</a></li>
                <li><a href="/">Post Graduate</a></li>
                <li><a href="/">Diploma</a></li>
                <li><a href="/">Professional Courses</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/">Our Story</a></li>
                <li><a href="/">Partner with us</a></li>
                <li><a href="/">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
              <form onSubmit={handleSubmit}>
                <input 
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
    </div>
  );
}

export default Home;