import React from 'react';

import profileImage from '../images/Dr.-Jacskon-1-square.jpg'; // Ensure you update this path

const ProfilePage = () => {
  return (
    <div className="profile-page">
        
      <header className="profile-header">
        <h1 style={{fontWeight:'bold', fontSize:'70px'}}>RODNEY B. JACKSON</h1>
      </header>
    
      <div className="profile-content">
        <img className="profile-image" src={profileImage} alt="Benson Karanja" />
        <div className="profile-text">
          <h2 style={{fontWeight:'bold' }}>RODNEY B. JACKSON, PSY.D., M.DIV</h2>
          <h2 style={{fontWeight:'bold' }}>Academic Dean
          Vice President of Academic Affairs</h2>
          <ul style={{fontSize:'20px' }}>
            <li >Psy. D., Alliant International University</li>
            <li>M.Div., Interdenominational Theological Center</li>
            <li>B.A., Morehouse College</li>
            </ul>
          <p>
          Dr. Rodney Jackson serves as Associate Dean of Academics and Chairman of the Department of Business and Leadership Studies. With an extensive background in Faith-Based, Philanthropic and Non-Profit organizations his expertise spans crisis management, conflict resolution, and capital fundraising. He is a Certified John Maxell Coach, Teacher, Trainer, and Speaker. As a Global Leader with both ministry and marketplace experience, he has a proven record of success in taking some of the most influential organizations in the world to the next level of leadership.

Recognized as one of the Top 50 Ministers in The U.S. by Upscale Magazine for demonstrating superior leadership, innovation, and commitment to community improvement he is renowned as a pioneer, transformational leader, and inventor. Formerly the Chief Operating Officer of Gospel Tabernacle, historically one of the largest religious organizations in the southeast he leads a team that includes some of today’s most prominent Christian leaders. Through his leadership was also the creation of a Denominational/Non-Denominational Leadership Training Program that now serves as the model blueprint for hundreds of religious organizations globally.

Dr. Jackson frequently teaches undergraduate and graduate-level courses in Macroeconomics, Nonprofit Management, Strategic Leadership, Systematic Theology and Hermeneutics as well as the Executive Leadership Coaching for Transformational Ministry Certificate where he is Visiting Professor of Leadership with Beulah Heights University’s International Educational Partnerships in Brazil, Kenya, Congo, Korea, and Tokyo. With a strong background in Qualitative Research Design and research/publication interests in Multigenerational Leadership Development, Organizational Succession Planning and International & Global Educational Management.
       </p>
         
          
        </div>
      
      </div>
      <div class="back-button-container">
      <a href="/About" class="back-button">
        <span class="arrow">&#8592;</span> BACK TO FACULTY & STAFF DIRECTORY
      </a>
    </div>
    </div>
  );
};

export default ProfilePage;