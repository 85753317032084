import React from 'react';

import profileImage from'../images/faculty_wilson-wes.jpg'; // Ensure you update this path

const ProfilePage = () => {
  return (
    <div className="profile-page">
        
      <header className="profile-header">
        <h1 style={{fontWeight:'bold', fontSize:'70px' }}>Wes Wilson</h1>
      </header>
    
      <div className="profile-content">
        <img className="profile-image" src={profileImage} alt="Benson Karanja" />
        <div className="profile-text">
          <h2 style={{fontWeight:'bold' }}>Wes Wilson</h2>
          <h2 style={{fontWeight:'bold' }}>Vice President for Student Success
          Department Chair for Leadership and Business Studies</h2>
          <ul style={{fontSize:'20px' }}>
            <li >Ph.D., Columbia International University</li>
            <li>M.A., Southeastern Christian University</li>
            <li>B.A., Beulah Heights University</li>
            </ul>
           
        </div>
      
      </div>
      <div class="back-button-container">
      <a href="/About" class="back-button">
        <span class="arrow">&#8592;</span> BACK TO FACULTY & STAFF DIRECTORY
      </a>
    </div>
    </div>
  );
};

export default ProfilePage;