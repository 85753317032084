import React from 'react';

import profileImage from '../images/staff_benson-karanja21-22.jpg'; // Ensure you update this path

const ProfilePage = () => {
  return (
    <div className="profile-page">
        
      <header className="profile-header">
        <h1 style={{fontWeight:'bold', fontSize:'70px' }}>Benson Karanja</h1>
      </header>
    
      <div className="profile-content">
        <img className="profile-image" src={profileImage} alt="Benson Karanja" />
        <div className="profile-text">
          <h2>Benson Karanja, Ed.D. HSC</h2>
          <p>
          A native of Kenya, East Africa, Benson M. Karanja received his Bachelor of Arts degree from Beulah Heights University. He continued his education at Brenau University where he received a Masters of Business Administration and he completed his academic journey at Clark Atlanta University where he received a Master’s in Library Science and a Doctorate in Education.

Dr. Karanja became President of Beulah Heights University in 2004, making him the first African immigrant to become President of a US college. Dr. Karanja transformed the Beulah Heights campus by changing the very make up of the student body. Upon Dr. Karanja’s arrival at Beulah Heights, less than 8 countries were represented on campus. Today, students from over 45 countries are studying to increase their leadership skills and Bible knowledge. Many faculty, staff and students travel on short-term mission trips all over the world. Dr. Karanja succeeded in transforming the campus into one that reflects the nature of today’s interconnected global village.

Dr. Karanja is also involved in the facilitation and building of partnerships between organizations in the US and those in Africa, South America and Asia. Dr. Karanja also serves as a mentor for immigrant ethnic leaders,closing the gap on cultural thinking and helping forge lasting strategic partnerships. His service on the East African Business council has provided opportunities to advise American Business people on how to start businesses and invest in East Africa. His interests in building partnerships and connecting people and resources led Dr. Karanja to start the African Resource Group and African Resource Foundation to help in this mission.

Through his efforts in the non-profit sector, Dr. Karanja has brought together a myriad of organizations from the US to unite in common purposes which have resulted in many successful non-profit endeavors. For example, Dr. Karanja was able to bring together Kenyan and American members from different denominations and independent churches to open a community center in Kenya. This center provides education to over 5,000 young people. The clinic cares for Aids patients and the ophans of Aids victims. More than 14 denominations joined together to make this life changing resource available.

Dr. Karanja is a distinguished member of several Boards including Calvary Leadership Institute, Gospel Magazine, AFG, ARG Moi African Institute among many others. He also serves as Vice Chairman of TRACS (Transnational Association of Christian Colleges and Schools), He has been honored as Man of the year by Secretary of State, one of the most influential black leaders in Atlanta by 2007’s who’s who among Atlanta.</p>
          <p>
          Dr. Karanja brings a unique perspective and voice on African issues because of his very unique life journey. He was born when Kenya and the rest of the continent was embroiled in the struggle for independence. As he explains in his autobiography, his father was a cook for the English colonialists and he grew up herding goats while receiving an education partly funded by British missionaries. He came of age in a rapidly changing world where the old ways were being replaced by new foreign ways. His boyhood heroes would encompass this shifting world. Jomo Kenyatta-the first president of Kenya, Muhammad Ali, and John F. Kennedy remain his inspirations even to this day.

From these very humble beginnings he became a successful business man in Kenya. He then decided to pursue his education which led him to the UnitedStates – where he was again to start over as a student immigrant with a family of five. Through sheer hard work and the internalization of theAmerican spirit which he saw made up of perseverance and optimism, Dr. Karanja has been able to achieve the American dream. Dr. Karanja professional accomplishments and his personal journey, along with his travels around the world, give him a unique perspective and a unique voice that will resonate, inform and maybe inspire your international audience. </p>
<h2>Benson Karanja, Ed.D. HSC</h2>
          <p>
            Dr. Karanja is also involved in the facilitation and building of partnerships between organizations in the US and those in Africa, South America and Asia. Dr. Karanja also serves as a mentor for immigrant ethnic leaders, closing the gap on cultural thinking and helping forge lasting strategic partnerships. His service on the East African Business council has provided opportunities to advise American Business people on how to start businesses and invest in East Africa. His interests in building partnerships and connecting people and resources led Dr. Karanja to start the African Resource Group and African Resource Foundation to help in this mission.
          </p>
          
        </div>
      
      </div>
      <div class="back-button-container">
      <a href="/About" class="back-button">
        <span class="arrow">&#8592;</span> BACK TO FACULTY & STAFF DIRECTORY
      </a>
    </div>
    </div>
  );
};

export default ProfilePage;