import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import YouTubePlayer from './YouTubePlayer';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/download.png';



function Media() {
  const videos = [
    {id: 1, videoId: 'PkvaC1T_4II', title: 'Beulah Heights University Africa'},
    {id: 2, videoId: '0nb7Grv6TKU', title: 'Beulah Heights University Africa'},
  ];

  return (
    <div className="begin">
    <h1 className="text-center" style={{ color: 'white', fontWeight: 'bolder', backgroundColor:'#004080', maxWidth:'100%', margin: '0' }}>OUR MEDIA</h1>
    <br/>
  
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {videos.map(video => (
        <YouTubePlayer key={video.id} videoId={video.videoId} title={video.title} />
      ))}
    </div>
 
  

  <br/>
  <br/>

      <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(254) 743 777 277</a></li>
                <li><a href="mailto:info@beulahheights.edu">info@beulahheights.edu</a></li>
                <li><a href="www.beulahheights.edu">www.beulahheights.edu</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights University Africa</strong></h4>
              <ul>
                <li>4240 North 15th Avenue</li>
                <li>Phoenix, AZ 85015</li>
                <li>Tel (254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="/Academics">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="/">Graduate</a></li>
                <li><a href="/">Post Graduate</a></li>
                <li><a href="/">Diploma</a></li>
                <li><a href="/">Professional Courses</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/">Our Story</a></li>
                <li><a href="/">Partner with us</a></li>
                <li><a href="/">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
              <input type="text" placeholder="Email Address" />
            </div>
          
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
    </div>
  );
}

export default Media;