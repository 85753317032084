import React, { useEffect, useState } from 'react';

const PayPalButton = ({ amount, onSuccess }) => {
  const [paypalLoaded, setPaypalLoaded] = useState(false);

  useEffect(() => {
    // Load PayPal JavaScript SDK
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=AdmM_QttKSpbE-eMfQHiI8_RXiYyI8ZMo5G86XXyBBshFIdz9WvMDHFGs-kECIkaa_dhpxh-oqXuaUI_`; // Replace YOUR_CLIENT_ID with your actual Client ID
    script.addEventListener('load', () => {
      setPaypalLoaded(true);
    });
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount,
              },
            }],
          });
        },
        onApprove: async (data, actions) => {
          await actions.order.capture();
          onSuccess();
        },
        onError: (err) => {
          console.error('PayPal Checkout onError:', err);
        },
      }).render('#paypal-button-container');
    }
  }, [paypalLoaded, amount, onSuccess]);

  return (
    <div>
      <div id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalButton;