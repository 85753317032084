import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/inc/Navbar';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Academics from './components/pages/Academics';
import Admissions from './components/pages/Admissions';
import Contactus from './components/pages/Contactus';
import Media from './components/pages/Media';
import Partner from './components/pages/Partner';
import AcademicPartners from './components/pages/AcademicPartners';
import Supportbhu from './components/pages/Supportbhu';
import InquirePage from './components/pages/InquirePage';
import ProfilePage from './components/pages/ProfilePage';
import ProfilePage2 from './components/pages/ProfilePage2';
import ProfilePage3 from './components/pages/ProfilePage3';
import ProfilePage4 from './components/pages/ProfilePage4';
import Scholarship from './components/pages/Scholarship';


function App() {
  const [suggestions, setSuggestions] = useState([]);
  const data = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/About' },
    { label: 'Academics', path: '/Academics' },
    { label: 'Admissions', path: '/Admissions' },
    { label: 'Contact Us', path: '/Contactus' },
    { label: 'Media', path: '/Media' },
    { label: 'Partner', path: '/Partner' },
    { label: 'Academic Partners', path: '/AcademicPartners' },
    { label: 'Support BHU', path: '/Supportbhu' },
    { label: 'Inquire', path: '/inquire' },
    { label: 'Profile Page', path: '/ProfilePage' },
    { label: 'Profile Page 2', path: '/ProfilePage2' },
    { label: 'Profile Page 3', path: '/ProfilePage3' },
    { label: 'Profile Page 4', path: '/ProfilePage4' }
  ];
  const handleSearch = (query) => {
    if (query) {
      const filteredSuggestions = data.filter(item =>
        item.label.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };
  return (
    <Router>
   <Navbar onSearch={handleSearch} suggestions={suggestions} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inquire" element={<InquirePage />} />
        <Route path="/ProfilePage" element={<ProfilePage />} />
        <Route path="/ProfilePage2" element={<ProfilePage2 />} />
        <Route path="/ProfilePage3" element={<ProfilePage3 />} />
        <Route path="/ProfilePage4" element={<ProfilePage4 />} />
        <Route path="/About" element={<About />} />
        <Route path="/Academics" element={<Academics />} />
        <Route path="/Admissions" element={<Admissions />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Media" element={<Media />} />
        <Route path="/Partner" element={<Partner />} />
        <Route path="/AcademicPartners" element={<AcademicPartners />} />
        <Route path="/Supportbhu" element={<Supportbhu />} />
        <Route path="/Scholarship" element={<Scholarship />} />
       
      </Routes>
    </Router>
  );
}

export default App;
