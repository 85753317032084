import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import MainLogo from '../images/Bhu-logo-wiki.png';
import $ from 'jquery'; 

const Navbar = ({ onSearch, suggestions }) => {
  const [admissionsOpen, setAdmissionsOpen] = useState(false);
  const [partnerOpen, setPartnerOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleInquireHover = () => {
    setIsHovered(true);
  };

  const handleInquireLeave = () => {
    setIsHovered(false);
  };

  const handleAdmissionsHover = () => {
    setAdmissionsOpen(true);
  };

  const handleAdmissionsLeave = () => {
    setAdmissionsOpen(false);
  };

  const handlePartnerHover = () => {
    setPartnerOpen(true);
  };

  const handlePartnerLeave = () => {
    setPartnerOpen(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value);
    setShowSuggestions(true);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      const firstSuggestion = suggestions[0];
      if (firstSuggestion) {
        if (firstSuggestion.external) {
          window.open(firstSuggestion.path, '_blank');
        } else {
          window.location.href = firstSuggestion.path;
        }
      }
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setShowSuggestions(false);
    if (suggestion.external) {
      window.open(suggestion.path, '_blank');
    } else {
      window.location.href = suggestion.path;
    }
  };

  useEffect(() => {
    $(document).ready(function(){
      $('.dropdown-submenu a.test').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
    });
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        
        <a href="/">
          <div className="logo">
            <img src={MainLogo} alt="Beulah Heights University Africa" />
          </div>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="https://beulah.edu/">BHU USA</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link active dropdown-toggle" href="/About" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                About
              </a>
              <ul className="dropdown-menu">
                
                <li><a className="dropdown-item" href="/Media">Media</a></li>
                <li className="dropdown-submenu" onMouseEnter={handlePartnerHover} onMouseLeave={handlePartnerLeave}>
                  <a className="dropdown-item dropdown-toggle" href="#" role="button" aria-expanded="false">
                    Partner With Us
                  </a>
                  <ul className={`dropdown-menu ${partnerOpen ? 'show' : ''}`}>
                    <li><a className="dropdown-item" href="/AcademicPartners">Academic Partner</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="/Supportbhu">Support BHU Africa</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link active dropdown-toggle" href="https://beulah.edu/academics/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Academics
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/master-programs/">Masters Programs</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/doctoral-programs/">Doctoral Programs</a></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/adultlearning/">Professional Learning</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/global-learning/">Global Learning</a></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/online/">Online Programs</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/registrar/">Registration</a></li>
                <li><a className="dropdown-item" href="https://beulah.edu/academics/library/">Library</a></li>
                <li className="dropdown-submenu" onMouseEnter={handleAdmissionsHover} onMouseLeave={handleAdmissionsLeave}>
                  <a className="dropdown-item dropdown-toggle test" href="#" tabIndex="-1" id="admissionsDropdown" role="button" aria-expanded="false">
                    Admissions
                  </a>
                  <ul className={`dropdown-menu ${admissionsOpen ? 'show' : ''}`} aria-labelledby="admissionsDropdown">
                    <li><a className="dropdown-item" href="https://beulah.jenzabarcloud.com/studapp.cfm">Apply Now</a></li>
                    <li><a className="dropdown-item" href="https://beulah.edu/admissions/international/">International Students</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="https://beulah.edu/admissions/tuition-fees/">Tuition Fees</a></li>
                    <li><a className="dropdown-item" href="https://beulah.edu/covid/">COVID-19 Preparedness</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="https://beulah.edu/admissions/transfercredit/">Transfer Credit</a></li>
                    <li><a className="dropdown-item" href="https://beulah.edu/foreigntranscript/">Foreign Transcript Evaluation</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="https://beulah.edu/admissions/financial-aid-scholarships/">Financial Aid & Scholarships</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link active dropdown-toggle" href="https://beulah.edu/admissions/financial-aid-scholarships/institutional-scholarships/" role="button" aria-expanded="false">
              Scholarships
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/Scholarship">Africa Outreach Scholarship</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/Contactus" className="nav-link active">Contact Us</Link>
            </li>
          </ul>
          <div className="actions">
            <div className="phone-and-buttons">
              <span className="phone-number">(254) 743 777 277</span>
              <div className="button-group">
                <a
                  href="/inquire"
                  className="btns" 
                  onMouseEnter={handleInquireHover}
                  onMouseLeave={handleInquireLeave}
                >
                  Inquire
                </a>
              </div>
            </div>
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleSearch}
              />
              {showSuggestions && query && (
                <ul className="list-group position-absolute w-100" style={{ top: '100%', zIndex: 1000 }}>
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="list-group-item list-group-item-action"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.label}
                    </li>
                  ))}
                </ul>
              )}
            </form>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
