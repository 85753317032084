import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({videoId, title}) => {
  
  // Options for the YouTube player
  const opts = {
    height: '315',
    width: '560',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,  // Disable autoplay
      controls: 1,  // Show player controls
    },
  };

  return (
    <div style={{ marginBottom: '20px', borderRadius: '8px', overflow: 'hidden' }}>
    <YouTube videoId={videoId} opts={opts} />
    <div style={{ padding: '5px', background: '#004080', color: '#ffffff'}}>
      <h2>{title}</h2>
    </div>
  </div>
  );
};

export default YouTubePlayer;