import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/download.png';

function Partner() {
  return (
    <div className="begin">
      <div className="Headings">
        <h2>ACADEMIC PARTNERS</h2>
      </div>
      <br/>
      <p>
        <strong>Welcome esteemed academic partners,</strong></p>

       <p> WE are thrilled to extend our warmest greetings to each of you as valued members of our academic community.
        Your dedication to education, research, and collaboration plays an instrumental role in shaping the future of academia and beyond.</p>

        <p>Together, we embark on a journey of innovation, discovery, and excellence. Your expertise and passion contribute significantly to the 
        advancement of knowledge, fostering a dynamic environment where ideas flourish and boundaries are pushed.</p>

        <p>As partners, we cherish the opportunity to collaborate, learn, and grow alongside you. Your unique perspectives and contributions enrich
        our collective endeavors, propelling us towards new horizons of academic achievement.</p>

        <p>We are excited to explore the possibilities that lie ahead and to witness the transformative impact of our collective efforts. Together, let
        us continue to inspire, challenge, and empower one another to reach greater heights of academic excellence.</p>

        <p>Thank you for your unwavering commitment and dedication. Here's to a fruitful and rewarding partnership ahead.</p>

        <p>Warm regards,</p>

        <p><strong>Beulah Heights University Africa.</strong>
      </p>

      <div className="Headings">
        <h2>SUPPORT BHU AFRICA</h2>
      </div>
      <br/>
      <p>
        Dear BHU Supporters,
        We are grateful for your commitment to Beulah Heights University. Your
        contributions serve our institution, students, and our mission to offer
        education grounded in faith and service to the community.
        Because of your support, we are able to:
        . Empower Students: Your donations help us provide scholarships,
        resources, and opportunities to our students so that they can pursue
        their dreams and make a meaningful difference in the communities in
        which they live.
        . Enhance Facilities: With your support, we have improved our
        campus facilities, thus creating an environment where students can
        learn and thrive.
        . Expand Programs: Your supprot enables us to expand our program
        offerings, ensuring that we offer relevant education.
        Please consider continuing your partnership with Beulah Heights
        University. Your support is vital for helping us sustain our mission and
        expand our impact.
        Ways to Give:
        . Online Giving: You can make a secure online donation through our 
        website at https://beulah.edu/support-bhu/
        . By Mail: If you prefer to send your contribution by mail, please make
        your check payable to Beulah Heights University and send it to:
        Beulah Heights University 892 Berne Street Atlanta, GA, 30316.

        Together, we can make a difference in the lives of students around the
        world. Thank you for being essential to the Beulah Heights University
        family and mission. Your generosity inspires us and strengthens our
        resolve to fulfil our mission.
      </p>
     
        <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(254) 743 777 277</a></li>
                <li><a href="mailto:info@beulahheights.edu">info@beulahheights.edu</a></li>
                <li><a href="www.beulahheights.edu">www.beulahheights.edu</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights University Africa</strong></h4>
              <ul>
                <li>4240 North 15th Avenue</li>
                <li>Phoenix, AZ 85015</li>
                <li>Tel (254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="/Academics">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="/">Graduate</a></li>
                <li><a href="/">Post Graduate</a></li>
                <li><a href="/">Diploma</a></li>
                <li><a href="/">Professional Courses</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/">Our Story</a></li>
                <li><a href="/">Partner with us</a></li>
                <li><a href="/">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
              <input type="text" placeholder="Email Address" />
            </div>
          
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
    </div>
  );
}

export default Partner;