import React from 'react';

import profileImage from '../images/Julian+Kyula-removebg-preview.png'; // Ensure you update this path

const ProfilePage = () => {
  return (
    <div className="profile-page">
        
      <header className="profile-header">
        <h1 style={{fontWeight:'bold', fontSize:'70px' }}>Julian Kyula</h1>
      </header>
    
      <div className="profile-content">
        <img className="profile-image" src={profileImage} alt="Julian Kyula" />
        <div className="profile-text">
          <h2>Julian Kyula</h2>
          <p>
          Julian is an internationally accomplished business leader and serial FinTech entrepreneur. In 2010, he founded the MODE Group, a FinTech company spanning over 26 countries in Africa, Asia and the Middle East. As Founder and Chairman of Beulah City, Julian was a key negotiator in a Government of Kenya and UNOPS agreement to deliver 100,000 affordable homes in Kenya. Julian sits on several boards across the world with links to global leaders in various sectors. He was recognized as the 2015 CNBC East Africa Entrepreneur of the Year, 2012 IBM Global Entrepreneur and a Top 40 under 40 Kenyan entrepreneur on 2 occasions. He featured in Forbes Magazine, Bloomberg International and the New York Times. Julian is also a sought after speaker and has spoken at the Global Entrepreneurship Summit in Nairobi with President Barack Obama. In addition, Julian is a reverend and the senior pastor of the Nairobi-based Purpose Centre Church. </p>
          
        </div>
      
      </div>
      <div class="back-button-container">
      <a href="/About" class="back-button">
        <span class="arrow">&#8592;</span> BACK TO FACULTY & STAFF DIRECTORY
      </a>
    </div>
    </div>
  );
};

export default ProfilePage;